@import '@amzn/awsui-global-styles/polaris.css';

@font-face {
  font-family: 'AmazonEmberRegular';
  src: url('./assets/fonts/AmazonEmber_Rg.ttf');
}

@font-face {
  font-family: 'AmazonEmberDisplayLt';
  src: url('./assets/fonts/AmazonEmberDisplay_Lt.ttf');
}

@font-face {
  font-family: 'AmazonEmberDisplayBd';
  src: url('./assets/fonts/AmazonEmberDisplay_Bd.ttf');
}

body,
html {
  height: 100%;
  font-family: 'AmazonEmberRegular', Helvetice Neue Light, Helvetica Neue,
    Helvetica, Arial;
  margin: 0;
  /* overflow-y: scroll; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root_display {
  display: flex;
  flex-direction: column;
}